import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { FaCoffee } from "react-icons/fa"
import React from 'react'
import Seo from '../components/Seo'
import '../styles/szablon_globalny.css'

export default function Wizytowka() {
  return (

    <div className='card_box'>

      <Seo 
        title="Wizytówka FizjoPersonalna"
        description="Aktualne informacje o działalności firmy. Zapraszam do współpracy."
        url="https://www.fizjopersonalny.pl/wizytowka"
      />
      
      <div className='card_img'>
        <Link to="/">
          <StaticImage src="../images/wizytowka.png" alt="FizjoPersonalny - Łukasz Kaleńczuk" />
        </Link>
      </div>
      <div className='card_name'>
        <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">@fizjopersonalny</a>
      </div>
      <p>Cześć, z tej strony Łukasz Kaleńczuk. Jestem fizjoterapeutą z powołania oraz trenerem personalnym z zamiłowania. Pomogę Ci odzyskać formę na każdym poziomie sprawności, szczególnie jeśli bolą Cię plecy czy kolana.</p>
      <a href="https://buycoffee.to/fizjopersonalny" target="_blank" rel="noreferrer">
        <div className='card_coffee'>
          <div><FaCoffee /></div>
          <div>Postaw kawę</div>
        </div>
      </a>
      <p>Moim celem jest wyposażyć Cię w wiedzę oraz narzędzia, które poprawią jakość Twojego życia.</p>
      <p></p>
      <Link to="/kontakt" className='button'>Umów wizytę</Link>
      <p>W tym miejscu znajdziesz zawsze aktualne materiały związane z moją działalnością.</p>
      <ul>
        <li>
          <em>BLOG</em>
          <Link to="/blog/trening-autogenny-schultza">Trening autogenny Schultza</Link>
        </li>
        <li>
          <em>SKLEP</em>
          <a href="https://fizjopersonalny.sklep.pl/shop/activebook/biurowa-kamasutra-41-pozycji-przy-biurku-dla-zasiedzianych/" target="_blank" rel="noreferrer">Biurowa Kamasutra (poradnik dla zasiedzianych)</a>
          {/* <a href="https://fizjopersonalny.sklep.pl/shop/activebook/biurowa-kamasutra-41-pozycji-przy-biurku-dla-zasiedzianych/" className='card_new' target="_blank" rel="noreferrer">NOWOŚĆ</a> */}
        </li>
        <li>
          <em>YOUTUBE</em>
          <a href="https://youtu.be/DqsfSu6oFQI" target="_blank" rel="noreferrer">Ból pleców podczas codziennych czynności - 9 praktycznych zaleceń</a>
        </li>
        <li>
          <em>LEARNBOOK</em>
          <a href="https://fizjopersonalny.sklep.pl/shop/learnbook/przy-biurku-bez-bolu/" target="_blank" rel="noreferrer">Przy biurku bez bólu [webinar]</a>
        </li>
        <li>
          <em>ACTIVEBOOK</em>
          <a href="https://fizjopersonalny.sklep.pl/shop/activebook/wytrzymale-i-stabilne-plecy/" target="_blank" rel="noreferrer">Wytrzymałe i stabilne plecy [kurs]</a>
        </li>
      </ul>
      <p>Chcesz nawiązać współpracę i wspólnie promować zdrowy styl życia?</p>
      <br />
      <Link to="/kontakt" className='button'>Napisz do mnie</Link>
      <br />
    </div>

  )
}